// import { captureException } from '@sentry/gatsby';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import useResources from '../../../hooks/useResources';
import Pagination from '../../../molecules/Pagination/Pagination';
import Section from '../../../molecules/Section/Section';
import { decamelize, toCamelCase } from '../../../utils/helperFunctions';
import {
  clearParams,
  getParams,
  updateParamsSilently,
} from '../../../utils/url/handleParams';
import ResourceCard from '../../Cards/ResourcesCard';
import ResourceFilters from './Filters/ResourceFilters';
import {
  CardContainer,
  Heading,
  NoResults,
} from './styles/ResourcesListing.styled';

const initialState = {
  search: '',
  shopType: '',
  shopTypes: [],
  resourceType: '',
  resourceTypes: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_SHOP_TYPE':
      return { ...state, shopType: action.payload };
    case 'SET_SHOP_TYPES':
      return { ...state, shopTypes: action.payload };
    case 'SET_RESOURCE_TYPE':
      updateParamsSilently(
        'type',
        toCamelCase(action.payload),
        'replace',
        'resources',
        true
      );
      return { ...state, resourceType: action.payload };
    case 'SET_RESOURCE_TYPES':
      return { ...state, resourceTypes: action.payload };
    case 'CLEAR':
      clearParams(true);
      return { ...state, search: '', shopType: '', resourceType: '' };
    default:
      console.log('FAQ Search Failure', state, action);
  }
};

const ResourcesListing = () => {
  const resourcesSection = useRef(null);
  const { resources } = useResources();
  const [filterState, filterDispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);
  const pageLimit = 12;
  const hasFilters = Boolean(
    filterState.shopType || filterState.resourceType || filterState.search
  );

  const filteredResources = hasFilters
    ? resources.filter(resource => {
        const shopFilter = filterState.shopType
          ? resource.tags.some(tag => tag.name === filterState.shopType)
          : true;

        const resourceFilter = filterState.resourceType
          ? resource.tags.some(
              tag =>
                tag.name.toLowerCase() ===
                filterState.resourceType.toLowerCase()
            )
          : true;
        const searchFilter =
          resource.name
            .toLowerCase()
            .includes(filterState.search.toLowerCase()) ||
          resource.excerpt
            .toLowerCase()
            .includes(filterState.search.toLowerCase()) ||
          resource.tags.some(tag =>
            tag.name.toLowerCase().includes(filterState.search.toLowerCase())
          );

        return shopFilter && resourceFilter && searchFilter;
      })
    : resources;

  const currentPageResources =
    filteredResources &&
    filteredResources.slice((page - 1) * pageLimit, page * pageLimit);
  const totalPage = Math.ceil(filteredResources.length / pageLimit);

  const getFilteredResource = value => {
    // Return the resource label based on the camelCase value
    switch (value) {
      case 'ebook':
        return 'eBook';

      case 'pdfResource':
        return 'PDF Resource';

      default:
        return decamelize(value);
    }
  };

  useEffect(() => {
    const params = getParams(['type', 'p']);

    params?.type[0] &&
      filterDispatch({
        type: 'SET_RESOURCE_TYPE',
        payload: getFilteredResource(params.type[0]),
      });

    params?.p[0] && setPage(parseInt(params.p[0], 10));
  }, []);

  const goPage = pageNumber => {
    setPage(pageNumber);
    updateParamsSilently('p', pageNumber, 'replace', 'resources', true);

    if (resourcesSection.current) {
      resourcesSection.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (currentPageResources.length === 0) {
      setPage(1);
      updateParamsSilently('p', '', 'remove', '', true);

      if (resourcesSection.current) {
        resourcesSection.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [currentPageResources.length]);

  return (
    <Section
      ref={resourcesSection}
      id="resources"
      innerStyle={{ display: 'flex', flexFlow: 'column nowrap', gap: '32px' }}
    >
      <Heading>Shopmonkey's Latest</Heading>
      <ResourceFilters
        state={filterState}
        dispatch={filterDispatch}
        resources={resources}
      />
      {currentPageResources.length > 0 ? (
        <CardContainer>
          {currentPageResources.map(resource => (
            <ResourceCard
              excerpt={resource.excerpt}
              image={resource.image}
              key={resource.id}
              link={resource.url}
              metaLabel={resource.metaLabel}
              tags={resource.tags}
              title={resource.name}
              wordCount={resource.wordCount}
            />
          ))}
        </CardContainer>
      ) : (
        <NoResults>0 results found</NoResults>
      )}
      {totalPage > 1 && (
        <Pagination totalPage={totalPage} currentPage={page} goPage={goPage} />
      )}
    </Section>
  );
};

export default ResourcesListing;
